import * as React from 'react';
import Box from '@mui/material/Box';

export default function Title(props) {
  const { text, logo } = props;
  return (
    <Box 
      sx={{ 
        p: 5,
        m: 1,
        borderRadius: 1,
        textAlign: 'center',
        fontWeight: '700',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        alignContent: 'space-between',
        fontSize: 'h4.fontSize'
      }}
    >
    {logo && <React.Fragment><img style={{ width: 50, display: 'inline-block' }} src={logo} alt="Logo" />&nbsp;</React.Fragment>}
    {text}
    </Box>
  );
}