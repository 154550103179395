import React, { Fragment, useState } from 'react';
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function Modal({ open, onClose, onAction, error }) {
    const [state, setState] = useState({
        email: undefined,
        message: undefined
    })

    function handleChange(evt) {
        const value = evt.target.value;
        setState({
            ...state,
            [evt.target.name]: value
        });
    }

    return <Fragment>
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Contact</DialogTitle>
            <DialogContent>
                {error && <Alert severity="error">{error}</Alert>}<br/>
                <DialogContentText>
                    Merci de renseigner votre email et le contenu de votre message.
                </DialogContentText>
                <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label="Email"
                    type="email"
                    variant="standard"
                    name="email"
                    value={state.email}
                    onChange={handleChange}
                    required
                    fullWidth
                />
                <TextField
                    autoFocus
                    margin="dense"
                    id="message"
                    label="Message"
                    type="text"
                    variant="standard"
                    multiline
                    minRows={4}
                    maxRows={4}
                    name="message"
                    value={state.message}
                    onChange={handleChange}
                    required
                    fullWidth
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Annuler</Button>
                <Button onClick={() => onAction(state)}>Envoyer</Button>
            </DialogActions>
        </Dialog>
    </Fragment>
}