import React, { Fragment, useState } from 'react';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';

export default function Form({ initState, onAction }) {
  const DAY_MILLISECONDS = 1000 * 60 * 60 * 24

  const [state, setState] = useState({
    reference: new Date(),
    type: "CONFIRMED",
    vaccinated: true,
    hasSymptoms: false,
    isAdult: true,
    symptoms: new Date(),
    ...initState
  })

  function handleClear() {
    setState({
      reference: new Date(),
      type: "CONFIRMED",
      vaccinated: true,
      hasSymptoms: false,
      isAdult: true,
      symptoms: new Date()
    })
  }

  function handleChange(evt) {
    if (Array.isArray(evt)) {
      setState({
        ...state,
        ...evt.reduce((reduced, e) => { 
          return { ...reduced, [e.target.name]: e.target.value }
        }, {})
      });
    } else {
      setState({
        ...state,
        [evt.target.name]: evt.target.value
      });
    }
  }

  return (
    <div style={{ width: '100%', textAlign: 'left' }}>
      <Grid container spacing={2}>
        <Grid item md={4} sm={3} xs={0}></Grid>
        <Grid item md={4} sm={6} xs={12}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Stack spacing={3}>
              <DatePicker
                disableFuture
                name="reference"
                label="Date de référence du diagnostic ( J0 )"
                openTo="day"
                views={['day']}
                value={state.reference}
                onChange={(value) => { 
                  handleChange([
                    { target: { name: "symptoms", value }},
                    { target: { name: "reference", value }}
                  ])
                }}
                renderInput={(params) => <TextField {...params} />}
                inputFormat="dd/MM/yyy"
              />
            </Stack>
          </LocalizationProvider>
        </Grid>
        <Grid item md={4} sm={3} xs={0}></Grid>    

        <Grid item md={4} sm={3} xs={0}></Grid>
        <Grid item md={4} sm={6} xs={12}>
          <FormControl fullWidth>
            <InputLabel id="situation-label">Situation</InputLabel>
            <Select
              labelId="situation-label"
              id="situation-select"
              name="type"
              value={state.type}
              label="Situation"
              onChange={handleChange}
            >
              <MenuItem value="CONFIRMED">Cas Confirmé ( Test PCR / antigénique positif )</MenuItem>
              <MenuItem value="CONTACT">Cas Contact ( Test PCR / antigénique négatif )</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item md={4} sm={3} xs={0}></Grid>

        <Grid item md={4} sm={3} xs={0}></Grid>
        <Grid item md={4} sm={6} xs={12}>
          <FormControl fullWidth>
            <InputLabel id="age-label">Age</InputLabel>
            <Select
              labelId="age-label"
              id="age-select"
              name="isAdult"
              value={state.isAdult}
              label="Age"
              onChange={handleChange}
            >
              <MenuItem value={false}>Moins de 12 ans</MenuItem>
              <MenuItem value={true}>Plus de 12 ans</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item md={4} sm={3} xs={0}></Grid>

        <Grid item md={4} sm={3} xs={0}></Grid>
        <Grid item md={4} sm={6} xs={12}>
          <FormControl fullWidth>
            <InputLabel id="vaccination-label">Vaccination</InputLabel>
            <Select
              labelId="vaccination-label"
              id="vaccination-select"
              name="vaccinated"
              value={state.vaccinated}
              label="Vaccination"
              onChange={handleChange}
            >
              <MenuItem value={true}>Vacciné</MenuItem>
              <MenuItem value={false}>Non Vacciné</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item md={4} sm={3} xs={0}></Grid>

        {state.type==="CONFIRMED" && <Fragment>
            <Grid item md={4} sm={3} xs={0}></Grid>
            <Grid item md={4} sm={6} xs={12}>
              <FormControl fullWidth>
                <InputLabel id="symptoms-label">Symptômes</InputLabel>
                <Select
                  labelId="symptoms-label"
                  id="symptoms-select"
                  name="hasSymptoms"
                  value={state.hasSymptoms}
                  label="Symptômes"
                  onChange={handleChange}
                >
                  <MenuItem value={true}>Présence de Symptômes</MenuItem>
                  <MenuItem value={false}>Aucun Symptôme</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={4} sm={3} xs={0}></Grid>
          </Fragment>
        }

        {state.type==="CONFIRMED" && state.hasSymptoms && <Fragment>
          <Grid item md={4} sm={3} xs={0}></Grid>
          <Grid item md={4} sm={6} xs={12}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Stack spacing={3}>
                <DatePicker
                  name="symptoms"
                  label="Début des Symptômes"
                  openTo="day"
                  views={['day']}
                  value={state.symptoms}
                  onChange={(value) => { handleChange({ target: { name: "symptoms", value }}) }}
                  renderInput={(params) => <TextField {...params} />}
                  inputFormat="dd/MM/yyy"
                  maxDate={state.reference}
                  minDate={new Date(state.reference.getTime() - DAY_MILLISECONDS * 7)}
                />
              </Stack>
            </LocalizationProvider>
          </Grid>
          <Grid item md={4} sm={3} xs={0}></Grid>    
        </Fragment>}

        <Grid item md={4} sm={3} xs={0}></Grid>    
        <Grid item md={4} sm={6} xs={12}>
          <FormControl fullWidth>
            <Grid container spacing={2}>
              <Grid item md={6} sm={6} xs={12}>
                <Button style={{padding:15}} fullWidth variant="outlined" onClick={() => handleClear()} >Réinitialiser</Button>
              </Grid>
              <Grid item md={6} sm={6} xs={12}>
                <Button style={{padding:15}} fullWidth variant="contained" onClick={() => onAction(state)} >Résultat</Button>
              </Grid> 
            </Grid>          
          </FormControl>
        </Grid>
        <Grid item md={4} sm={3} xs={0}></Grid>     
      </Grid>
    </div>
  )
}