import React from 'react';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import HomeIcon from '@mui/icons-material/Home';
import LockClockIcon from '@mui/icons-material/LockClock';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import InfoIcon from '@mui/icons-material/Info';
import { useTheme } from '@mui/styles';

export default function Form({ result, onAction }) {
  const {isolationRequired, isolationEnd, comment, tracking } = result 
  const theme = useTheme();

  return (
    <div style={{ width: '100%', textAlign: 'left' }}>
      <Grid container spacing={2}>
        <Grid item md={4} sm={3} xs={0}></Grid>
        <Grid item md={4} sm={6} xs={12}>
          <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
            <ListItem>
              <ListItemAvatar>
                <Avatar sx={{ bgcolor: theme.palette.primary.main }}>
                  <HomeIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={isolationRequired ? "ISOLEMENT REQUIS" : "PAS D'ISOLEMENT"} />
            </ListItem>
            {isolationRequired && <ListItem>
              <ListItemAvatar>
                <Avatar sx={{ bgcolor: theme.palette.primary.main }}>
                  <LockClockIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={isolationEnd} secondary="FIN D'ISOLEMENT" />
            </ListItem>}
            <ListItem>
              <ListItemAvatar>
                <Avatar sx={{ bgcolor: theme.palette.primary.main }}>
                  <InfoIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary="Commentaire" secondary={comment} />
            </ListItem>
            <ListItem>
              <ListItemAvatar>
                <Avatar sx={{ bgcolor: theme.palette.primary.main }}>
                  <PersonSearchIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary="Tracing" secondary={tracking} />
            </ListItem>
          </List>
        </Grid>
        <Grid item md={4} sm={3} xs={0}></Grid>    

        <Grid item md={4} sm={3} xs={0}></Grid>    
        <Grid item md={4} sm={6} xs={12}>
          <FormControl fullWidth>
            <Button style={{padding:15}} fullWidth variant="contained" onClick={() => onAction()} >Retour</Button>
          </FormControl>
        </Grid>
        <Grid item md={4} sm={3} xs={0}></Grid>     
      </Grid>
    </div>
  )
}