import './App.css';
import React, { useState } from 'react';
import Fab from '@mui/material/Fab';
import MessageIcon from '@mui/icons-material/Message';
import InfoIcon from '@mui/icons-material/Info';
import MenuIcon from '@mui/icons-material/Menu';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Drawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { Space } from './Space';
import { SituationForm } from './SituationForm';
import { SituationResult } from './SituationResult';
import { Title } from './Title';
import { getDiagnostic, submitForm } from './functions'
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import logo from './logo.svg'
import { ContactModal } from './ContactModal';
import { InfoModal } from './InfoModal';

// import { Search } from './Search';
// import { Event } from './Event'
// import Stack from '@mui/material/Stack';

function App() {

  const [state, setState] = useState({
    view: "FORM",
    result: undefined,
    infoOpen: false,
    contactOpen: false,
    contactError: undefined,
    menuOpen: false,
    data: {}
  })

  const onContactModalAction = async (data) => {
    if(!data.email || !data.message) {
      return setState({
        ...state,
        contactError: "Formulaire incomplet. Merci de renseigner tous les champs" 
      })
    }

    const success = await submitForm('contact', data)

    if(success) {
      setState({
        ...state,
        contactOpen: false,
        contactError: undefined 
      })
    } else {
      setState({
        ...state,
        contactError: "Une erreur est survenue. Merci de réessayer ultérieurement." 
      })
    }
  }

  const onSituationFormAction = (data) => {
    const result = getDiagnostic(data)
    setState({
      ...state,
      data: data,
      view: "RESULT",
      result
    })
  }

  const onSituationFormClear = (data) => {
    setState({
      ...state,
      data: {}
    })
  }

  const onSituationResultAction = (data) => {
    setState({
      ...state,
      view: "FORM",
      result: undefined
    })
  }

  const onToggleContact = () => {
    setState({
      ...state,
      contactOpen: !state.contactOpen,
      contactError: undefined
    })
  }

  const onToggleInfo = () => {
    setState({
      ...state,
      infoOpen: !state.infoOpen
    })
  }

  const onToggleMenu = () => {
    setState({
      ...state,
      menuOpen: !state.menuOpen
    })
  }

  const theme = createTheme({
    palette: {
      primary: {
        main: '#428fdd',
        light: '#FFFFFF'
      }  
    }
  })

  return (
    <ThemeProvider theme={theme}>    
      <LocalizationProvider dateAdapter={AdapterDateFns}>
          <InfoModal open={state.infoOpen} onClose={onToggleInfo}></InfoModal>
          <ContactModal open={state.contactOpen} error={state.contactError} onClose={onToggleContact} onAction={onContactModalAction}></ContactModal> 
          <Fab  size="small" style={{ position: 'fixed', left:5, top: 5 }} color="primary" aria-label="menu" onClick={onToggleMenu}><MenuIcon /></Fab>
          <Space>
            <Title logo={logo} text="EDUCOV" />
            {state.view === 'FORM' && <SituationForm initState={state.data} onClear={onSituationFormClear} onAction={onSituationFormAction} />}
            {state.view === 'RESULT' && <SituationResult result={state.result} onAction={onSituationResultAction} />}
            {/* <Search placeholder="Rechercher une personne" />    
            <Event open={true} />
            <Button variant="contained">Déclarer un événement</Button>
            <Button variant="contained">Obtenir un résultat</Button> */}
          </Space>
          <Drawer
            anchor="left"
            open={state.menuOpen}
            onClose={onToggleMenu}
          >
            <Box
              sx={{ width: 250, height: "100%", bgcolor: theme.palette.primary.main }}
              role="presentation"
              onKeyDown={onToggleMenu}
            >
              <List>
                <ListItem 
                  button 
                  key="info" 
                  onClick={onToggleInfo}          
                >
                  <ListItemIcon>
                    <InfoIcon sx={{ color: theme.palette.primary.light }}/>
                  </ListItemIcon>
                  <ListItemText sx={{ color: theme.palette.primary.light }} primary="Informations" />
                </ListItem>
                <ListItem 
                  button 
                  key="contact" 
                  onClick={onToggleContact}          
                >
                  <ListItemIcon>
                    <MessageIcon sx={{ color: theme.palette.primary.light }}/>
                  </ListItemIcon>
                  <ListItemText sx={{ color: theme.palette.primary.light }} primary="Contact" />
                </ListItem>                
              </List>
            </Box>
          </Drawer>
      </LocalizationProvider>
    </ThemeProvider>
  );
}

export default App;
