const formatDate = (date) => {
    var dd = date.getDate();
    var mm = date.getMonth() + 1;
    var yyyy = date.getFullYear();
    if (dd < 10) {
        dd = '0' + dd;
    }
    if (mm < 10) {
        mm = '0' + mm;
    }
    return dd + '/' + mm + '/' + yyyy;
}

export default function getDiagnostic({ reference, type, vaccinated, hasSymptoms, symptoms, isAdult }) {
    const DAY_MILLISECONDS = 1000 * 60 * 60 * 24
    const result = {}

    switch(type) {
        case "CONFIRMED":
        default:
            switch(hasSymptoms) {
                case true: 
                    result.tracking = `recherche des cas contacts du ${formatDate(new Date(symptoms.getTime() - DAY_MILLISECONDS * 2))} (J-2) au ${formatDate(new Date(reference.getTime()))}`
                break;
                case false: 
                default:
                    result.tracking = `recherche des cas contacts du ${formatDate(new Date(reference.getTime() - DAY_MILLISECONDS * 7))} (J-7) au ${formatDate(new Date(reference.getTime()))}`
            }

            if(vaccinated || !isAdult) {
                result.isolationRequired = true
                result.isolationEnd = formatDate(new Date(reference.getTime() + DAY_MILLISECONDS * 7))
                result.comment = `réduction possible au ${formatDate(new Date(reference.getTime() + DAY_MILLISECONDS * 5))} (J+5) en cas de test négatif et d'absence de symptômes depuis 48h`
            } else {
                result.isolationRequired = true
                result.isolationEnd = formatDate(new Date(reference.getTime() + DAY_MILLISECONDS * 10))
                result.comment = `réduction possible au ${formatDate(new Date(reference.getTime() + DAY_MILLISECONDS * 7))} (J+7) en cas de test négatif et d'absence de symptômes depuis 48h`
            }
        break;
        case "CONTACT":
            result.tracking = `aucune recherche de contact nécessaire`

            if(vaccinated || !isAdult) {
                result.isolationRequired = false
                result.isolationEnd = null
                result.comment = `autotests requis le ${formatDate(new Date(reference.getTime() + DAY_MILLISECONDS * 2))} (J+2) et le ${formatDate(new Date(reference.getTime() + DAY_MILLISECONDS * 4))} (J+4) ${!isAdult ? ' avec attestation sur l\'honneur des parents':''}`
            } else {
                result.isolationRequired = true
                result.isolationEnd =formatDate(new Date(reference.getTime() + DAY_MILLISECONDS * 14))
                result.comment =`réduction possible au ${formatDate(new Date(reference.getTime() + DAY_MILLISECONDS * 7))} (J+7) en cas de test antigénique/PCR négatif`
            }
        break;
    }

    return result;
}