import React, { Fragment } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Link from '@mui/material/Link';

export default function Modal({ open, onClose }) {
    return <Fragment>
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
        <DialogTitle id="alert-dialog-title">
            {"Educov"}
        </DialogTitle>
        <DialogContent>
            <DialogContentText id="alert-dialog-description">
                Déterminez rapidement les mesures de confinement adaptées pour un élève ou un membre du personnel en milieu éducatif à partir de son état d'exposition au virus de la covid 19.<br/><br/>
                L'algorithme utilisé a été défini à partir des <Link target="_blank" rel="noreferrer" href="https://www.education.gouv.fr/covid19-mesures-pour-les-ecoles-colleges-et-lycees-modalites-pratiques-continuite-pedagogique-et-305467">règles gouvernementales officielles</Link>
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={onClose} autoFocus>Fermer</Button>
        </DialogActions>
        </Dialog>
    </Fragment>
}